<template>
  <div class="dashboard">
    <v-navigation-drawer
      v-model="drawer"
      class="fulheight"
      width="256"
      app
      style="background-color: #505050"
    >
      <v-card-text style="background-color: #4f5e88; height: 75px; align-items: center;">
        <img style="height:50px;width: auto;margin-top: -9px;margin-left: -10px;"   src="~@/assets/logoBI.png"/>
      </v-card-text>

      <v-list>
        <v-list-item
          v-for="item in items"
          :key="item.title"
          link
          tag="router-link"
          :to="item.to"
        >
          <v-list-item-content class="itemtitle">
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar app fixed height="75px" color="#4F5E88">
      <v-app-bar-nav-icon
        style="color: white"
        @click.stop="drawer = !drawer"
      ></v-app-bar-nav-icon>
      <VSpacer />
    </v-app-bar>
    <div class="fullheight pa-5">
      <router-view></router-view>
    </div>
  </div>
</template>
<script>
export default {
  name: "Dashboard",
  data() {
    return {
      drawer: true,
      items: [
        { title: "Dashboard", to: "/" },
        // { title: "Production Analysist", to: "/production" },
        // { title: "Transportation Analysist", to: "/transportation" },
        { title: "SPARQL Generator", to: "/SPARQLGen"},
      ],
    };
  },
};
</script>
<style scoped>
.fullheight {
  min-height: 100vh !important;
}
.router {
  text-decoration: none;
  color: black;
}
</style>